<template>
  <div class="google-map">
    <gmap-autocomplete
      class="form-control w-100 mb-3"
      @place_changed="handlePlaceChanged"
      :value="value"
    >
      <template v-slot:input="{ text, setText }">
        <input
          :value="text"
          class="form-control w-100"
          placeholder="Masukkan Lokasi MU"
          @input="setText($event.target.value)"
        >
      </template>
    </gmap-autocomplete>
    <p
      v-if="error"
      class="error-message"
    >
      {{ error }}
    </p>
    <gmap-map
      :center="mapCenter"
      :zoom="12"
      class="map-container"
      @click="handleMapClick"
    >
      <gmap-marker
        v-if="markerPosition"
        :position="markerPosition"
        :draggable="true"
        @dragend="handleMarkerDragEnd"
      />
    </gmap-map>
    <div
      v-if="markerPosition"
      class="coordinates"
    >
      Latitude: {{ markerPosition.lat.toFixed(6) }},
      Longitude: {{ markerPosition.lng.toFixed(6) }}

      {{ selectedPlace }}
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'GoogleMap',
  props: ['bloodvan', 'value'],
  data() {
    return {
      mapCenter: { lat: -7.028067, lng: 107.543502 },
      markerPosition: { lat: -7.028067, lng: 107.543502 },
      error: null,
      selectedPlace: null,
    }
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    if (this.value) {
      this.geocodePlace(this.value)
    }
  },
  methods: {
    handlePlaceChanged(place) {
      this.error = null
      if (place.geometry) {
        this.updateLocationFromPlace(place)
      } else {
        this.geocodePlace(place.name)
      }
    },
    updateLocationFromPlace(place) {
      const { location } = place.geometry
      this.updateMapAndMarker(location.lat(), location.lng())
      this.extractAddress(place)
    },
    geocodePlace(query) {
      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ address: query }, this.handleGeocodeResult)
    },
    extractAddress(place) {
      let addressComponents = {}

      place.address_components.forEach(component => {
        const types = component.types

        if (types.includes('street_number')) {
          addressComponents.streetNumber = component.long_name
        }
        if (types.includes('route')) {
          addressComponents.street = component.long_name
        }
        if (types.includes('locality')) {
          addressComponents.city = component.long_name
        }
        if (types.includes('administrative_area_level_1')) {
          addressComponents.state = component.long_name
        }
        if (types.includes('country')) {
          addressComponents.country = component.long_name
        }
        if (types.includes('postal_code')) {
          addressComponents.postalCode = component.long_name
        }

        const formattedAddress = place.formatted_address

        this.$emit('address-extracted', {
          formattedAddress,
          components: addressComponents,
          latitude: this.markerPosition.lat,
          longitude: this.markerPosition.lng,
        })
      })
    },
    handleGeocodeResult(results, status) {
      if (status === 'OK' && results[0]) {
        const { location } = results[0].geometry
        this.updateMapAndMarker(location.lat(), location.lng())
        this.extractAddress(results[0])
      } else {
        this.handleGeocodeError(status)
      }
    },
    handleGeocodeError(status) {
      this.error = `Unable to find location. Status: ${status}`
      this.markerPosition = null
    },
    handleMapClick(event) {
      this.updateMapAndMarker(event.latLng.lat(), event.latLng.lng())
    },
    handleMarkerDragEnd(event) {
      this.updateMapAndMarker(event.latLng.lat(), event.latLng.lng())
    },
    updateMapAndMarker(lat, lng) {
      this.mapCenter = { lat, lng }
      this.markerPosition = { lat, lng }
    },
  },
}
</script>

<style scoped>
.google-map {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.map-container {
  width: 100%;
  height: 250px;
}
.error-message {
  color: red;
  margin-bottom: 10px;
}
.coordinates {
  margin-top: 10px;
  font-weight: bold;
}
</style>
